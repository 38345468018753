import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { breakpoint } from '../breakpoints/breakpoints';
import Flex from './flex';
import ButtonRound from './button-round';
import ButtonLink from './button-link';

const HeroWrapper = styled.div`
  position: relative;
  height: 100vh;
  background-color: ${(props) => props.bgcolor};

  .gatsby-image-wrapper {
    height: 100vh;
  }

  @media ${breakpoint.mobileXL} {
    height: 80vh;

    .gatsby-image-wrapper {
      height: 80vh;
    }
  }

  @media ${breakpoint.laptopL} {
    height: 75vh;

    .gatsby-image-wrapper {
      height: 75vh;
    }
  }
`;

const ContentWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const FlexWrapper = styled(Flex)`
  width: 100%;
  display: none;
  color: ${(props) => props.color};

  @media ${breakpoint.laptop} {
    display: flex;
    padding: 24px 34px 0 34px;
  }

  & span {
    display: inline-block;
    padding-right: 7px;
    svg {
      margin-bottom: -3px;
    }
  }
`;

const Title = styled.span`
  color: ${(props) => props.color};
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  margin: 48px auto 0 auto;

  @media ${breakpoint.tablet} {
    height: 76px;
  }
  @media ${breakpoint.laptop} {
    margin: 0 auto;
  }
  @media ${breakpoint.laptopXL} {
    margin: 20px auto;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 500px;
  height: 64px;
  margin: 48px auto 0 auto;

  @media ${breakpoint.mobileS} {
    height: 52px;
    max-width: 380px;
  }
  @media ${breakpoint.tablet} {
    height: 54px;
    max-width: 500px;
  }
  @media ${breakpoint.laptop} {
    margin: 0 auto;
  }
  @media ${breakpoint.laptopXL} {
    margin: 20px auto;
  }
`;

const Caption = styled.h1`
  color: ${(props) => props.color};
  text-align: center;
  padding: 0 15px;
  font-size: 36px;

  @media ${breakpoint.mobileL} {
    padding: 0 40px;
    font-size: 40px;
  }

  @media ${breakpoint.mobileXL} {
    margin: 25px 40px;
  }
`;

const ButtonWrapper = styled(Flex)`
  width: 100%;
  position: absolute;
  bottom: 90px;
  left: 0;

  @media ${breakpoint.tablet} {
    position: static;
    padding: 0 150px;
    flex-direction: row;
  }
`;

const ButtonWrapper3 = styled(Flex)`
  width: 100%;
  position: absolute;
  bottom: 60px;
  left: 0;

  @media ${breakpoint.tablet} {
    position: static;
    padding: 0 150px;
    flex-direction: row;
  }
`;

const ImgDiv = styled(Flex)`
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  height: 55px;
  width: 100%;
  overflow: hidden;
`;

const Img1 = styled.img`
  width: 100%;
  height: 55px;
  max-width: 444px;
  padding: 0 33px;
  @media ${breakpoint.mobileXL} {
    padding: 10px;
  }
`;

const Img2 = styled.img`
  display: none;
  width: 100%;
  height: auto;
  max-width: 444px;
  @media ${breakpoint.mobileXL} {
    display: inline-block;
    padding: 10px;
  }
`;

function Hero(props) {
  return (
    <HeroWrapper bgcolor={props.heroContent.heroFallbackBg}>
      {props.heroContent.heroUrl !== undefined && (
        <Img fluid={props.heroContent.heroUrl} />
      )}

      <ContentWrapper justify="flex-start" align="center" column>
        <FlexWrapper
          color={props.heroContent.titleColor}
          justify="space-between"
        >
          <a href={`mailto:${props.heroContent.email}`}>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsSvgjs="http://svgjs.com/svgjs" xmlnsXlink="http://www.w3.org/1999/xlink" width="18" height="18">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 128 96">
                  <path fill="#fff" d="M0 11.283V8a8 8 0 0 1 8-8h112a8 8 0 0 1 8 8v3.283l-64 40zm66.12 48.11a4.004 4.004 0 0 1-4.24 0L0 20.717V88a8 8 0 0 0 8 8h112a8 8 0 0 0 8-8V20.717z">
                  </path>
                </svg>
              </svg>
            </span>
            {props.heroContent.email}
          </a>
          <a href={`tel:${props.heroContent.phone}`}>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsSvgjs="http://svgjs.com/svgjs" xmlnsXlink="http://www.w3.org/1999/xlink" width="18" height="18">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32">
                  <path fill="#fff" d="M13.216 8.064c-.382-4.394-4.966-6.55-5.16-6.638a.974.974 0 0 0-.582-.078c-5.292.878-6.088 3.958-6.12 4.086a.99.99 0 0 0 .02.54c6.312 19.584 19.43 23.214 23.742 24.408.332.092.606.166.814.234a.99.99 0 0 0 .722-.042c.132-.06 3.248-1.528 4.01-6.316a.997.997 0 0 0-.096-.612c-.068-.132-1.698-3.234-6.218-4.33a.977.977 0 0 0-.884.21c-1.426 1.218-3.396 2.516-4.246 2.65-5.698-2.786-8.88-8.132-9-9.146-.07-.57 1.236-2.572 2.738-4.2a.998.998 0 0 0 .26-.766z" >
                  </path>
                </svg>
              </svg>
            </span>
            {props.heroContent.phone}
          </a>
        </FlexWrapper>

        <Flex justify="center">
          {props.heroContent.pageLogo !== undefined && (
            <Logo
              src={props.heroContent.pageLogo}
              alt={props.heroContent.pageTitle}
            ></Logo>
          )}

          {props.heroContent.showTitle === true && (
            <Title color={props.heroContent.titleColor}>
              {props.heroContent.pageTitle}
            </Title>
          )}
        </Flex>

        <Caption color={props.heroContent.captionColor}>
          {props.heroContent.caption}
        </Caption>

        {props.buttonContent !== undefined &&
          props.buttonContent.button.length < 3 && (
            <ButtonWrapper column justify="center" align="center">
              {props.buttonContent.button.map((button, index) => {
                return (
                  <ButtonRound
                    key={index}
                    bgColor={button.bgColor}
                    textColor={button.textColor}
                    hoverColor={button.hoverColor}
                    text={button.text}
                    page=""
                    section={button.hash}
                  />
                );
              })}
              <ButtonLink
                key="4"
                bgColor={props.buttonContent.button[0].bgColor}
                textColor={props.buttonContent.button[0].textColor}
                hoverColor={props.buttonContent.button[0].hoverColor}
                text="Zu meiner Website"
                href="https://www.markuslehnert.com/"
              />
            </ButtonWrapper>
          )}

        {props.buttonContent !== undefined &&
          props.buttonContent.button.length === 3 && (
            <ButtonWrapper3 column justify="center" align="center">
              {props.buttonContent.button.map((button, index) => {
                return (
                  <ButtonRound
                    key={index}
                    bgColor={button.bgColor}
                    textColor={button.textColor}
                    hoverColor={button.hoverColor}
                    text={button.text}
                    page=""
                    section={button.hash}
                  />
                );
              })}
            </ButtonWrapper3>
          )}
        <ImgDiv justify="center">
          {props.heroContent.heroOverlayUrl1 !== undefined && (
            <Img1 src={props.heroContent.heroOverlayUrl1} alt=""></Img1>
          )}
          {props.heroContent.heroOverlayUrl2 !== undefined && (
            <Img2 src={props.heroContent.heroOverlayUrl2} alt=""></Img2>
          )}
        </ImgDiv>
      </ContentWrapper>
    </HeroWrapper>
  );
}

export default Hero;
