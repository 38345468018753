import React from "react";
import styled from "styled-components";
import { breakpoint } from "../breakpoints/breakpoints";
import Flex from "./flex";
import Bulletpoint from "./bulletpoint";

const SectionWrapper = styled(Flex)`
  background-color: ${(props) => props.bgColor};
  padding: 60px 30px 90px 30px;
  text-align: center;

  @media ${breakpoint.tablet} {
    padding: 45px 30px 60px 30px;
  }
`;
const H2 = styled.h2`
  color: ${(props) => props.color};
`;
const ContentWrapperMobile = styled.div`
  text-align: left;
  max-width: 340px;
  margin-top: 40px;

  @media ${breakpoint.tablet} {
    display: none;
  }
`;

const ContentWrapperDesktop = styled.div`
  display: none;

  @media ${breakpoint.tablet} {
    display: block;
    text-align: left;
    margin-top: 40px;
    max-width: 870px;
    column-count: 2;
    column-gap: 100px;
  }
`;

const Div = styled.div`
  font-size: 17px;
  font-weight: 400;
  line-height: 181.18%;
  font-style: normal;
  margin-top: 0px;
  margin-bottom: 25px;
  display: inline-block;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  break-inside: avoid-column;
  @media ${breakpoint.tablet} {
    font-size: 15px;
  }
`;

function AboutWithBulletpoints(props) {
  return (
    <SectionWrapper
      bgColor={props.bgColor}
      column
      justify="center"
      align="center"
    >
      <H2 color={props.titleColor}>{props.sectionContent.title}</H2>

      <ContentWrapperMobile>
        {props.sectionContent.keyphrases.map((item, index) => {
          const half = props.sectionContent.keyphrases.length / 2;
          if (index < half) {
            return (
              <Div key={index}>
                <Flex align="center">
                  <Bulletpoint />
                  <span>{item}</span>
                </Flex>
              </Div>
            );
          } else {
            return null;
          }
        })}
      </ContentWrapperMobile>

      <ContentWrapperDesktop>
        {props.sectionContent.keyphrases.map((item, index) => {
          return (
            <Div key={index}>
              <Flex>
                <Bulletpoint />
                <span>{item}</span>
              </Flex>
            </Div>
          );
        })}
      </ContentWrapperDesktop>
    </SectionWrapper>
  );
}

export default AboutWithBulletpoints;
