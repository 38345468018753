import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Hero from "../components/hero";
import AboutWithMedia from "../components/about-with-media";
import AboutWithBulletpoints from "../components/about-with-bulletpoints";
import BookingSection from "../components/booking-section";
import TestimonialSection from "../components/testimonial-section";
import ContactSection from "../components/contact-section";
import ImgTextSection from "../components/imgtext-section";


function IndexPage({ data }) {
  if (!data) return null;

  const pageData = data.allPrismicLanding.edges[0].node.data;
  const docTitle = pageData.title.text;
  const docDescr = pageData.page_description.text;

  // Function creating objects to pass as props to sections
  const slices = pageData.body.map((slice) => {
    // Content for buttons on hero
    if (slice.slice_type === "button_on_hero") {
      const buttonDetails = slice.items.map((item) => {
        const button = {
          text: item.button_text.text,
          hash: item.section_hash.text,
          bgColor: item.button_color,
          textColor: item.text_color,
          hoverColor: item.hover_color,
        };
        return button;
      });
      const obj = {
        name: slice.slice_type,
        button: buttonDetails,
      };
      return obj;
    }

    // Content for about section with media
    if (slice.slice_type === "about_with_media") {
      const obj = {
        name: slice.slice_type,
        title: slice.primary.title_aboutmedia_section.text,
        subtitle: slice.primary.h3.text,
        content: slice.primary.content_aboutmedia_section.text,
        bildmarke:
          slice.primary.bildmarke.localFile !== null
            ? slice.primary.bildmarke.localFile.publicURL
            : undefined,
        url: slice.items[0].mediaurl.text,
        alt: slice.items[0].mediaalt.text,
        youtubeId: slice.items[0].youtube_id.text,
        description: slice.items[0].mediadescription.text,
      };
      return obj;
    }

    // Content for about section with list with bulletpoints
    if (slice.slice_type === "about_with_bulletpoints") {
      const items = slice.items.map(
        (item) => item.content_for_bulletpoint.text
      );
      const obj = {
        name: slice.slice_type,
        title: slice.primary.title_list_section.text,
        keyphrases: items,
      };
      return obj;
    }

    // Content for call to action section
    if (slice.slice_type === "call_to_action") {
      const items = slice.items.map((item) => {
        const content = {
          badgeColor: item.badge_color,
          badgeText: item.badge_text.text,
          description: item.description.text,
          price: item.price.text,
          priceColor: item.pricecolor,
          keyphrase1: item.keyphrase1.text,
          keyphrase2: item.keyphrase2.text,
          keyphrase3: item.keyphrase3.text,
          keyphrase4: item.keyphrase4.text,
          btnText: item.cta_btn_text.text,
          btnUrl: item.cta_url.text,
          btnColor: item.cta_btn_color,
          btnTextColor: item.cta_btn_textcolor,
        };
        return content;
      });

      const obj = {
        name: slice.slice_type,
        title: slice.primary.title_cta_section.text,
        content: items,
      };
      return obj;
    }

    // Content for short detail list on call to action section
    if (slice.slice_type === "short_detail_list") {
      const detailContent = slice.items.map((item) => {
        const detail = {
          title: item.detail_title.text,
          text: item.detail_text.text,
        };
        return detail;
      });

      const obj = {
        name: slice.slice_type,
        content: detailContent,
      };
      return obj;
    }

    // Content for testimonial section
    if (slice.slice_type === "testimonials") {
      const reviews = slice.items.map((item) => {
        const review = {
          customer: item.customer_name.text,
          city: item.customer_city.text,
          img:
            item.customer_picture.localFile !== null
              ? item.customer_picture.localFile.publicURL
              : undefined,
          text: item.customer_review.text,
        };
        return review;
      });

      const obj = {
        name: slice.slice_type,
        content: reviews,
      };
      return obj;
    } 
    
     // Content for images and text
     if (slice.slice_type === "images_and_text") {
      console.log(slice);

      const sliceContent = slice.items.map((item) => {
        const content = {
          title: item.imgtext_subtitle.text,
          text: item.imgtext_text.raw,
          img:
          item.imgtext_img.localFile !== null
            ? item.imgtext_img.localFile.childImageSharp.fluid
            : undefined,
          placement: item.img_placement
        };
        return content;
      });


      const obj = {
        name: slice.slice_type,
        title: slice.primary.title_imgtext_section.text,
        slice: sliceContent
      };
      return obj;
    } 
    else {
      return null;
    }
  });

  // Content für die hero section
  const heroContent = {
    pageTitle: pageData.title.text,
    showTitle: pageData.display_page_title,
    pageLogo:
      pageData.page_logo.localFile !== null
        ? pageData.page_logo.localFile.publicURL
        : undefined,
    caption: pageData.caption.text,
    email: pageData.email.text,
    phone: pageData.phone.text,
    heroUrl:
      pageData.hero.localFile !== null
        ? pageData.hero.localFile.childImageSharp.fluid
        : undefined,
    heroFallbackBg: pageData.hero_fallback_bgcolor,
    heroOverlayUrl1:
      pageData.herooverlay.localFile !== null
        ? pageData.herooverlay.localFile.publicURL
        : undefined,
    heroOverlayUrl2:
      pageData.herooverlay2.localFile !== null
        ? pageData.herooverlay2.localFile.publicURL
        : undefined,
    titleColor: pageData.titlecolor,
    captionColor: pageData.captioncolor,
  };

  // Content für die contact section
  const contactContent = {
    bigLogo:
      pageData.big_logo.localFile !== null
        ? pageData.big_logo.localFile
        : undefined,
    contactBg:
      pageData.contact_bg.localFile !== null
        ? pageData.contact_bg.localFile
        : undefined,
    contactFallbackBg: pageData.contact_fallback_bgcolor,
    contactCaption: pageData.contact_section_caption.text,
    captionColor: pageData.captioncolor,
    contactDescription: pageData.contact_section_description.text,
    contactName: pageData.contact_name.text,
    phone: pageData.phone.text,
    email: pageData.email.text,    
    whatsappLink: pageData.whatsapp_link,
    whatsappNumber: pageData.whatsapp_number,
    telegramLabel: pageData.telegram_label,
    telegramLink: pageData.telegram_link,
    facebookLabel: pageData.facebook_label,
    facebookLink: pageData.facebook_link,
    youtubeLabel: pageData.youtube_label,
    youtubeLink: pageData.youtube_link,
    contactColor: pageData.titlecolor,
    smallLogo1:
      pageData.small_logo1.localFile !== null
        ? pageData.small_logo1.localFile
        : undefined,
    smallLogo2:
      pageData.small_logo2.localFile !== null
        ? pageData.small_logo2.localFile
        : undefined,
        formTitle: pageData.title_contactform.text,
        formText: pageData.text_contactform.text
  };

  return (
    <Layout>
      <Helmet>
        <title>{docTitle}</title>
        <meta name="description" content={docDescr} />
      </Helmet>
      <Hero
        heroContent={heroContent}
        buttonContent={slices.find((slice) => slice.name === "button_on_hero")}
      />

      {slices.find((slice) => slice.name === "about_with_media") !==
        undefined && (
        <AboutWithMedia
          bgColor={pageData.bgcolor1}
          titleColor={pageData.h2color}
          borderColor={pageData.bgcolor2}
          sectionContent={slices.find(
            (slice) => slice.name === "about_with_media"
          )}
        />
      )}

{slices.find((slice) => slice.name === "images_and_text") !==
        undefined && (
        <ImgTextSection
          bgColor={pageData.bgcolor4}
          titleColor={pageData.h2color}
          borderColor={pageData.bgcolor2}
          sectionContent={slices.find(
            (slice) => slice.name === "images_and_text"
          )}
        />
      )}
      {slices.find((slice) => slice.name === "about_with_bulletpoints") !==
        undefined && (
        <AboutWithBulletpoints
          bgColor={pageData.bgcolor2}
          titleColor={pageData.h2color}
          sectionContent={slices.find(
            (slice) => slice.name === "about_with_bulletpoints"
          )}
        />
      )}

      {slices.find((slice) => slice.name === "call_to_action") !==
        undefined && (
        <BookingSection
          bgColorColorful={pageData.bgcolor3}
          bgColorLight={pageData.bgcolor1}
          bgColorDark={pageData.bgcolor4}
          titleColor={pageData.h2color}
          sectionContent={slices.find(
            (slice) => slice.name === "call_to_action"
          )}
          shortDetails={slices.find(
            (slice) => slice.name === "short_detail_list"
          )}
        />
      )}

      {slices.find((slice) => slice.name === "testimonials") !== undefined && (
        <TestimonialSection
          bgColor={pageData.bgcolor2}
          sectionContent={slices.find((slice) => slice.name === "testimonials")}
        />
      )}

<ContactSection sectionContent={contactContent} buttonContent={slices.find((slice) => slice.name === "button_on_hero")} formBgColor={pageData.bgcolor1} />
    </Layout>
  );
}

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    allPrismicLanding {
      edges {
        node {
          data {
            whatsapp_link
            whatsapp_number
            telegram_label
            telegram_link
            facebook_label
            facebook_link
            youtube_label
            youtube_link
            bgcolor1
            bgcolor2
            bgcolor3
            bgcolor4
            h2color
            hero_fallback_bgcolor
            contact_fallback_bgcolor
            text_contactform {
              text
            }
            title_contactform {
              text
            }
            caption {
              text
            }
            captioncolor
            email {
              text
            }
            hero {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 2400, toFormatBase64: WEBP) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            herooverlay {
              localFile {
                publicURL
              }
            }
            herooverlay2 {
              localFile {
                publicURL
              }
            }
            phone {
              text
            }
            title {
              text
            }
            titlecolor
            contact_name {
              text
            }
            page_description {
              text
            }
            contact_section_caption {
              text
            }
            contact_section_description {
              text
            }
            contact_bg {
              localFile {
                childImageSharp {
                  gatsbyImageData( 
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )             
                }
              }
            }
            big_logo {
              localFile {
                childImageSharp {
                  gatsbyImageData( 
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )             
                }   
              }
            }
            page_logo {
              localFile {
                publicURL
              }
            }
            small_logo1 {
              localFile {
                childImageSharp {
                  gatsbyImageData( 
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )             
                } 
              }
            }
            small_logo2 {
              localFile {
                childImageSharp {
                  gatsbyImageData( 
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )             
                } 
              }
            }
            body {
              ... on PrismicLandingBodyCallToAction {
                id
                items {
                  badge_color
                  badge_text {
                    text
                  }
                  description {
                    text
                  }
                  keyphrase1 {
                    text
                  }
                  keyphrase2 {
                    text
                  }
                  keyphrase3 {
                    text
                  }
                  keyphrase4 {
                    text
                  }
                  price {
                    text
                  }
                  pricecolor
                  cta_btn_color
                  cta_btn_text {
                    text
                  }
                  cta_url {
                    text
                  }
                  cta_btn_textcolor
                }
                primary {
                  title_cta_section {
                    text
                  }
                }
                slice_type
                slice_label
              }
              ... on PrismicLandingBodyAboutWithMedia {
                id
                items {
                  mediaalt {
                    text
                  }
                  mediadescription {
                    text
                  }
                  mediaurl {
                    text
                  }
                  youtube_id {
                    text
                  }
                }
                primary {
                  content_aboutmedia_section {
                    text
                  }
                  title_aboutmedia_section {
                    text
                  }
                  h3 {
                    text
                  }
                  bildmarke {
                    localFile {
                      publicURL
                    }
                  }
                }
                slice_type
                slice_label
              }
              ... on PrismicLandingBodyAboutWithBulletpoints {
                id
                items {
                  content_for_bulletpoint {
                    text
                  }
                }
                primary {
                  title_list_section {
                    text
                  }
                }
                slice_label
                slice_type
              }
              ... on PrismicLandingBodyTestimonials {
                id
                items {
                  customer_city {
                    text
                  }
                  customer_name {
                    text
                  }
                  customer_picture {
                    localFile {
                      publicURL
                    }
                  }
                  customer_review {
                    text
                  }
                }
                slice_label
                slice_type
              }
              ... on PrismicLandingBodyButtonOnHero {
                id
                items {
                  button_color
                  hover_color
                  section_hash {
                    text
                  }
                  button_text {
                    text
                  }
                  text_color
                }
                slice_type
                slice_label
              }
              ... on PrismicLandingBodyShortDetailList {
                id
                items {
                  detail_text {
                    text
                  }
                  detail_title {
                    text
                  }
                }
                slice_label
                slice_type
              }
              ... on PrismicLandingBodyImagesAndText {
                id
                primary {
                  title_imgtext_section {
                    text
                  }
                }
                items {
                  imgtext_img {
                    localFile {
                      childImageSharp {
                        fluid(quality: 100, maxWidth: 500, toFormatBase64: WEBP) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                      publicURL
                    }
                  }
                  img_placement
                  imgtext_text {
                    text
                    raw
                  }
                  imgtext_subtitle {
                    text
                  }
                }
                slice_label
                slice_type
              }
            }
            display_page_title
          }
        }
      }
    }
  }
`;
