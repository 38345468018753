import React, { useState } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, navigate } from "gatsby";
import { breakpoint } from "../breakpoints/breakpoints";
import Flex from "./flex";
import Mail from "../icons/Mail";
import Phone from "../icons/Phone";
import ButtonContact from "../components/button-contact";
import { Facebook, Telegram, WhatsAppSolid, Youtube } from "../icons";

const SectionWrapper = styled.div`
  position: relative;
  height: 956px;
  background-color: ${(props) => props.bgcolor};

  @media ${breakpoint.desktop} {
    height: 936px;
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
`;
const ContentWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const LogoWrapper = styled(Flex)`
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 856px;

  .gatsby-image-wrapper {
    height: auto;
    width: 210px;
    margin-top: 50px;
    @media only screen and (max-width: 537px) {
      width: 232px;
      margin: 0;
  }
  }

  @media ${breakpoint.desktop} {
    height: 936px;
  }


`;

const BigLogoImg = styled(GatsbyImage)`
  opacity: 0.6;
  mix-blend-mode: multiply;
  padding: 10px;
  padding-bottom: 50px;
  width: 100%;
  height: auto;

  @media ${breakpoint.laptop} {
    padding-bottom: 132px;
  }
`;

const CaptionWrapper = styled(Flex)`
  text-align: center;
  padding: 50px 60px 7px 60px;

  @media ${breakpoint.laptop} {
    padding-top: 100px;
  }

  @media ${breakpoint.laptopL} {
    padding-top: 132px 40px 14px 40px;
  }
`;

const Caption = styled.span`
  color: ${(props) => props.color};
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;

  @media ${breakpoint.mobileM} {
    font-size: 40px;
  }
`;

const Describtion = styled.span`
  color: ${(props) => props.color};
  display: block;
  text-align: center;
  opacity: 0.7;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
  line-height: 181.18%;
  padding: 0 20px;
`;

const Contact = styled(Flex)`
  color: ${(props) => props.color};
  position: absolute;
  width: 310px;
  top: 55%;
  left: 50%;
  padding: 0;
  transform: translateX(-50%);

  @media ${breakpoint.mobileM} {
    transform: none;
    right: 0;
    left: auto;
    width: auto;
    padding: 0 30px;
  }

  @media ${breakpoint.tablet} {
    right: 7%;
  }

  @media ${breakpoint.laptop} {
    left: 55%;
  }
  @media ${breakpoint.laptopL} {
    top: 60%;
    left: 55%;
  }
`;

const ContactName = styled.span`
  margin: 0;
  padding-bottom: 7px;
  font-weight: 700;
  font-size: 20px;
  line-height: 181.18%;

  @media ${breakpoint.laptop} {
    font-size: 25px;
  }
`;

const A = styled.a`
  display: inline-block;
  font-weight: 400;
  font-size: 20px;
  line-height: 182%;

  @media ${breakpoint.laptop} {
    font-size: 25px;
  } 
  @media ${breakpoint.mobileS} {
    font-size: 17px;
  }
  @media ${breakpoint.mobileL} {
    font-size: 20px;
  }

  & span {
    display: inline-block;
    padding-right: 20px;
  }
`;

const FlexWrapper = styled(Flex)`
  @media ${breakpoint.mobileM} {
    padding: 27px 28px 0 28px;
  }

  @media ${breakpoint.mobileXL} {
    justify-content: flex-start;
  }
`;

const LogoImg = styled(GatsbyImage)`
  margin: 9px;
  width: 72px;
  height: auto;
`;

const Footer = styled(Flex)`
  background-color: transparent;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  color: ${(props) => props.color};
  opacity: 0.6;
`;

const StyledLink = styled(Link)`
  padding: 10px 15px;
`;

// KONTAKTFORMULAR ///////////
const Overlay = styled(Flex)`
  position: fixed;
  z-index: 10;
  top: 0;
  left: ${props => (props.state ? 0 : -110)}vw;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
`;

const Div = styled(Flex)`
  position: fixed;
  z-index: 20;
  max-width: 400px;
  min-width: 320px;
  width: 90vw;
  height: auto;
  max-height: calc(100vh - 40px);
  background-color: ${(props) => props.bgColor};
  margin-top: 40px;
  padding: 40px 0 20px 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  & h3 {
    margin-bottom: 20px;
    color: ${(props) => props.h3Color};
  }
`;

const FormFlex = styled(Flex)`
overflow-y: scroll;
overflow-x: hidden;
padding-right: 30px;

  & button {
    margin-top: 20px;
  }
`;

const Input = styled.input`
  height: 42px;
  width: 100%;
  font-size: 15px;
  line-height: 15px;
  padding: 0 12px;
  margin: 12px 0;
`;

const Close = styled.span`
  position: absolute;
  top: -12px;
  right: -12px;
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.bgColor};
  cursor: pointer;
  border: 2px solid black;
  border-radius: 50%;

  & hover {
    background-color: ${(props) => props.hoverColor};
  }
`;

const Criss = styled.span`
  position: absolute;
  top: 12px;
  left: 3px;
  width: 20px;
  border-bottom: 2px solid ${(props) => props.borderColor};
  transform: rotate(45deg);
`;

const Cross = styled.span`
  position: absolute;
  top: 12px;
  left: 3px;
  width: 20px;
  border-bottom: 2px solid ${(props) => props.borderColor};
  transform: rotate(-45deg);
`;

const FooterImage = styled(GatsbyImage)` 
    width: 100%; 
`;

function ContactSection(props) {
  const [formIsOpen, setFormOpen] = useState(false);
  const [state, setState] = React.useState({})

  function openForm() {
    console.log('open form');
    setFormOpen(true);
  }

  function closeForm() {
    setFormOpen(false);
  }

  function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  function handleChange  (e) {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  function handleSubmit (e) {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <SectionWrapper
      id="contact"
      bgcolor={props.sectionContent.contactFallbackBg}
    >

      <Overlay align="center" justify="center" state={formIsOpen}>
        <Div
          column
          bgColor={props.formBgColor}
          h3Color={props.sectionContent.captionColor}
        >
          <Close
            onClick={closeForm}
            bgColor={props.buttonContent.button[0].bgColor}
            textColor={props.buttonContent.button[0].textColor}
            hoverColor={props.buttonContent.button[0].hoverColor}
          >
            <Criss borderColor={props.buttonContent.button[0].textColor} />
            <Cross borderColor={props.buttonContent.button[0].textColor} />
          </Close>
          <FormFlex column>
            <h3>{props.sectionContent.formTitle}</h3>
            <p>{props.sectionContent.formText}</p>

            <form
              name="Kontakt"
              method="POST"
              action="/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                </label>
              </p>

              <Input
                type="text"
                id="vorname"
                name="Vorname"
                placeholder="Vorname"
                onChange={handleChange}
                required
              />

              <Input
                type="text"
                id="nachname"
                name="Nachname"
                placeholder="Nachname"
                onChange={handleChange}
                required
              />

              <Input
                type="text"
                id="phonenumber"
                name="Telefonnummer"
                placeholder="Telefonnummer"
                onChange={handleChange}
                required
              />
              <ButtonContact
                type="submit"
                bgColor={props.buttonContent.button[0].bgColor}
                textColor={props.buttonContent.button[0].textColor}
                hoverColor={props.buttonContent.button[0].hoverColor}
              >
                Beratung anfordern
              </ButtonContact>

            </form>
          </FormFlex>
        </Div>
      </Overlay>

      {props.sectionContent.contactBg !== undefined && (
        // <Img fluid={props.sectionContent.contactBg} alt="" />
        <FooterImage image={getImage(props.sectionContent.contactBg)} />
      )}
      <ContentWrapper>
        {/* <LogoWrapper justify="center" align="center">
          <BigLogoImg image={getImage(props.sectionContent.bigLogo)} alt="" />
        </LogoWrapper> */}

        <CaptionWrapper justify="center" align="center">
          <Caption color={props.sectionContent.captionColor}>
            {props.sectionContent.contactCaption}
          </Caption>
        </CaptionWrapper>

        <Describtion color={props.sectionContent.captionColor}>
          {props.sectionContent.contactDescription}
        </Describtion>
        <Flex align="center" justify="center">
          <ButtonContact
            onClick={openForm}
            bgColor={props.buttonContent.button[0].bgColor}
            textColor={props.buttonContent.button[0].textColor}
            hoverColor={props.buttonContent.button[0].hoverColor}
          >
            Kontaktformular
          </ButtonContact>
        </Flex>

        <Contact color={props.sectionContent.contactColor} column>
          <ContactName>{props.sectionContent.contactName}</ContactName>
          <A href={`tel:${props.sectionContent.phone}`}>
            <span>
              <Phone />
            </span>
            {props.sectionContent.phone}
          </A>
          <A href={props.sectionContent.whatsappLink}>
            <span> 
              <WhatsAppSolid />
            </span>
            {props.sectionContent.whatsappNumber}
          </A>
          <A href={props.sectionContent.telegramLink}>
            <span>
              <Telegram />
            </span>
            {props.sectionContent.telegramLabel}
          </A>
          <A href={`mailto:${props.sectionContent.email}`}>
            <span>
              <Mail />
            </span>
            {props.sectionContent.email}
          </A>
          <A href={props.sectionContent.facebookLink}>
            <span>
              <Facebook />
            </span>
            {props.sectionContent.facebookLabel}
          </A>
          <A href={props.sectionContent.youtubeLink}>
            <span>
              <Youtube />
            </span>
            {props.sectionContent.youtubeLabel}
          </A>
          <FlexWrapper justify="center">
            <LogoImg image={getImage(props.sectionContent.smallLogo1)} />
            <LogoImg image={getImage(props.sectionContent.smallLogo2)} />
          </FlexWrapper>
        </Contact>
        <Footer justify="center" color={props.sectionContent.contactColor}>
          <StyledLink to="/impressum">Impressum</StyledLink>
          <StyledLink to="/datenschutz">Datenschutz</StyledLink>
        </Footer>
      </ContentWrapper>
    </SectionWrapper>
  );
}

export default ContactSection;
