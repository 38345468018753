import React from "react";
import styled from "styled-components";
import { breakpoint } from '../breakpoints/breakpoints';
import Img from "gatsby-image";
import Flex from "./flex";
import {RichText} from 'prismic-reactjs';

const SliceWrapper = styled(Flex)`
padding: 40px 0;
height: auto;

@media ${breakpoint.mobileXL} {
    flex-direction:  ${props => ((props.placement === "right") ? "row-reverse" : "row")};
}
`

const ImgWrapper = styled(Flex)`
padding: 10px;


  .gatsby-image-wrapper {
    height: 200px;
    width: 200px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
  }

  @media ${breakpoint.mobileM} {
    .gatsby-image-wrapper {
    height: 250px;
    width: 250px;
  }

 @media ${breakpoint.mobileXL} {
    width: 40%;
    min-width: 240px;
    padding: 20px;
    .gatsby-image-wrapper {
    height: 200px;
    width: 200px;
  }
}

 @media ${breakpoint.tablet} {
    width: 40%;
    min-width: 290px;
    padding: 20px;
    .gatsby-image-wrapper {
    height: 250px;
    width: 250px;   
    }
  }
}
`

const TextWrapper = styled(Flex)`

@media ${breakpoint.mobileXL} {
    align-content: "center";
    width: 60%;
    max-width: 680px;
    padding: 20px;
}
`

const P = styled.p`
text-align: left;
p {
  margin-bottom: 10px;
}
`




function ImgTextSlice (props) {
        return (
            <SliceWrapper column placement={props.content.placement}>
                <ImgWrapper align="center" justify="center">
                <Img imgStyle={{objectPosition: "top"}} fluid={props.content.img} />
                </ImgWrapper>

                <TextWrapper column align="flex-start">
                <h3>{props.content.title}</h3>
                <P>{RichText.render(props.content.text)}</P>
                </TextWrapper>
                
                
            </SliceWrapper>
        )
    }
    
    export default ImgTextSlice;
  