import React from "react";
import styled from "styled-components";

const A = styled.a`
  display: block;
  width: 100%;
  padding: 13px 21px;
  border: 1px solid transparent;
  border-radius: 6px;
  text-align: center;
  background: linear-gradient(
    120deg,
    ${(props) => props.textColor} 50%,
    ${(props) => props.bgColor} 50%
  );
  background-size: 300%;
  background-position: 100%;
  text-decoration: none;
  color: ${(props) => props.textColor};
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  cursor: pointer;
  margin: 16px 0;
  transition: all 0.9s;
  &:hover,
  :active {
    background-position: 0;
    color: ${(props) => props.bgColor};
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border: 1px solid ${(props) => props.bgColor};
  }
  &:visited,
  :focus {
    color: ${(props) => props.textColor};
  }
`;

function ButtonAngular(props) {
  return (
    <A href={props.href} bgColor={props.bgColor} textColor={props.textColor}>
      {props.text}
    </A>
  );
}

export default ButtonAngular;
