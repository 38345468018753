import React from "react";
import styled from "styled-components";
import { breakpoint } from '../breakpoints/breakpoints';
import Flex from "./flex";
import Quotation from "../icons/Quotation";


const Wrapper = styled(Flex)`
width: 100%;
margin-bottom: 74px;

@media ${breakpoint.tablet} { 
flex-direction: row;
align-items: flex-start;
}
@media ${breakpoint.laptopXL} { 
margin: 0 25px 25px 25px;
max-width: 500px;
}
`

const Left = styled(Flex)`
height: 150px;

@media ${breakpoint.tablet} { 
width: 150px;
flex: 0 0 150px;
align-items: "flex-start";
}
`
const Img = styled.img`
height: 150px;
width: 150px;
border-radius: 50%;
@media ${breakpoint.tablet} { 
height: 100px;
width: 100px;
}
`

const Right = styled(Flex)`
position: relative;
max-width: 500px;
min-width: 300px;
margin-top: 10px;

@media ${breakpoint.tablet} { 
margin-top: 0px;
}
`
const StyledQuotation = styled(Quotation)`
position: absolute;
left: 0;
top: -40px;
width: 35px;

@media ${breakpoint.tablet} { 
position: static;
}
`

const H4One = styled.h4`
display: none;
margin-top: 0;
margin-bottom: 12px;

@media ${breakpoint.tablet} { 
display: inline-block;
margin-top: 0;
margin-bottom: 12px;
}
`

const H4Two = styled.h4`
display: block;
margin: 8px auto 0px auto;

@media ${breakpoint.tablet} { 
display: none;
}
`

const P = styled.p`
margin-top: 8px;
font-style: italic;
`

const Light = styled.span`
font-weight: 400;
font-style: normal;
`


function Review(props) {

    return(
        <Wrapper column justify="center" align="center">
            <Left column justify="flex-start" align="flex-start">

            <H4One>{props.customer}<br/><Light>({props.city})</Light></H4One>
            <Img src={props.url} alt={`Porträtfoto von ${props.customer}`} /> 
            </Left>

            <Right column justify="flex-start" align="flex-start">
            <StyledQuotation />
            <P>{props.text}</P>
            <H4Two>{props.customer} <Light>({props.city})</Light></H4Two>
            </Right>

        </Wrapper>
        
    )
}

export default Review;